import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { servicePath } from '../constants/defaultValues';

const httpLink = createHttpLink({
  uri: `${servicePath}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  /* 
  let tokenLocal = JSON.parse(obtenerStateStorage('persist:root')?.auth)?.user?.strapiToken ||
  null;
  if (tokenLocal !== null) {
    return {
      headers: {
        ...headers,
        // authorization: `Bearer ${tokenLocal}`
      },
    };
  } */
  return {
    headers: {
      ...headers,
      // authorization: `Bearer ${tokenLocal}`
    },
  };
});
const client = new ApolloClient({
  uri: `${servicePath}/graphql`,
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

export const obtenerStateStorage = (tipo) => {
  try {
    const userStorage = localStorage.getItem(tipo);
    if (userStorage === null) {
      return undefined;
    }
    return JSON.parse(userStorage);
  } catch (error) {
    return undefined;
  }
};

export default client;
